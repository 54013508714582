<template>
  <div>
    <Title>
      <span class="phase-title left">
        <span>{{ phase.phaseName }}（</span>

        <Money :money="phase.quota" />
        <span>万元）</span>

        <!-- <a-button
          v-if="index !== 0"
          size="small"
          type="primary"
          style="margin-left: 10px"
          :disabled="!phase.configurable"
          @click="copy"
          >复制上一阶段</a-button
        > -->
      </span>
    </Title>

    <template v-if="phase.subCostQuotaList.length > 0">
      <div class="cost-title">专业分包（技术3）本阶段占比</div>
      <div class="cost">
        <a-row>
          <a-col
            :span="6"
            v-for="(item, index) in phase.subCostQuotaList"
            :key="index"
          >
            <a-tooltip>
              <template slot="title">
                {{ item.companyName }}
              </template>
              <template>
                <a-button type="primary">
                  <DataDictFinder
                    dictType="majorTypes"
                    :dictValue="item.major"
                  />
                </a-button>
              </template>
            </a-tooltip>
            <a-input-number
              :disabled="!phase.configurable"
              v-model="item.quota"
              :formatter="$ratioFormatter"
              :min="0"
              :max="100"
              style="width: 60px"
            />
          </a-col>
        </a-row>
      </div>
    </template>

    <div>
      <div class="cost-title">分配详情</div>
      <div class="list">
        <Item
          v-for="item in phase.twoList"
          :key="item.type"
          :item="item"
          :money="phase.quota * item.allocateQuota * 0.01"
          :phase="phase"
          :phaseIndex="index"
        />

        <div class="other-title">
          剩余占比 / 分配数值（
          {{ surplusRatio }}% / <Money :money="surplusMoney" />万元）
          <a-button
            type="primary"
            size="small"
            v-if="!phase.tec"
            :disabled="!phase.configurable"
            @click="addTec"
            >添加技术经济</a-button
          >
        </div>
        <div class="other-list">
          <template v-if="phase.tec">
            <Item
              :item="phase.tec"
              :money="tecMoney"
              @removeTec="removeTec"
              :phase="phase"
              :phaseIndex="index"
            />
          </template>

          <div
            style="
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85);
              margin-bottom: 12px;
            "
          >
            <span>
              专业可分配数值（
              <Money :money="majorMoney" />
              万元 ）
            </span>

            <a-button
              type="primary"
              size="small"
              @click="addMajorGroup"
              :disabled="!phase.configurable"
            >
              {{ phase.majorGroupList.length > 0 ? "复制子项" : "增加子项" }}
            </a-button>
          </div>

          <major-group-component
            v-for="(el, idx) in phase.majorGroupList"
            :key="idx"
            :item="el"
            :index="idx"
            :groupMoney="majorMoney * el.majorGroupQuota * 0.01"
            :majorGroupList="phase.majorGroupList"
            :phase="phase"
            :phaseIndex="index"
          />

          <a-button
            type="primary"
            style="margin-bottom: 12px"
            icon="plus"
            @click="plus"
            :disabled="!phase.configurable"
            v-if="phase.majorGroupList.length > 0"
            >增加专业</a-button
          >

          <Item
            v-for="(el, idx) in phase.otherList"
            :key="el.type"
            :item="el"
            :index="idx"
            :money="majorMoney * el.allocateQuota * 0.01"
            :list="phase.otherList"
            :phase="phase"
            :phaseIndex="index"
          />

          <div>
            <a-button
              type="primary"
              icon="plus"
              @click="addOther"
              :disabled="addOtherButtonDisabled || !phase.configurable"
              >增加其他</a-button
            >
            <span style="padding-left: 12px"
              >如果有施工配合，请增加其他人员分配</span
            >
          </div>
        </div>
      </div>

      <div class="cost-title">各专业分配文件上传</div>
      <div class="link-list">
        <a-checkbox-group :value="selectedFileList" @change="onSelectFile">
          <div class="link" v-for="item in phase.attachments" :key="item.id">
            <a-checkbox :value="item.id" :disabled="!phase.configurable">
              <a target="_blank" download :href="item.completePath">{{
                item.name
              }}</a>
            </a-checkbox>
          </div>
        </a-checkbox-group>

        <div class="control-bar" v-if="phase.configurable">
          <FileUpload @uploaded="uploaded">
            <div class="control">
              <a-icon type="upload" />
            </div>
          </FileUpload>

          <div class="control" @click="deleteFile">
            <a-icon type="delete" />
          </div>
        </div>
      </div>
    </div>

    <a-modal title="增加专业" :visible="visible" @cancel="cancel" @ok="ok">
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :data-source="majorTypes"
        bordered
        :pagination="false"
        :scroll="{ y: 240 }"
      >
        <a-table-column key="name" title="专业类型" data-index="name" />
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import Item from "./phase-item.vue";
import Title from "./title";
import MajorGroupComponent from "./major-group.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import FileUpload from "@/components/file-upload";

const roleRatioList = [
  {
    value: "major_charger",
    ratio: 0.1,
  },
  {
    value: "design",
    ratio: 0.6,
  },
  {
    value: "recheck",
    ratio: 0.15,
  },
  {
    value: "audit",
    ratio: 0.1,
  },
  {
    value: "approval",
    ratio: 0.05,
  },
];

export default {
  components: {
    Item, // 子阶段
    Title,
    MajorGroupComponent,
    FileUpload,
  },

  props: {
    // 阶段在阶段列表中的索引
    index: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      visible: false, // 模态框显示隐藏
      selectedRowKeys: [],

      selectedFileList: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    // 专业类型
    majorTypes() {
      return this.findDataDict("majorTypes").map((item) => ({
        ...item,
        key: item.value,
      }));
    },

    // 其他类型
    otherTypeList() {
      return this.findDataDict("sgphlx");
    },

    // 角色类型列表
    roleTypeList() {
      return this.findDataDict("prjAllocateRole");
    },

    ...mapState("allocate2024", ["list"]), // 阶段列表

    // 阶段信息根据索引取出来
    phase() {
      return this.list[this.index];
    },

    // 剩余占比，总金额 - 前两个
    surplusMoney() {
      let ratio = 0; // 总比例
      this.phase.twoList.forEach((item) => {
        ratio += item.allocateQuota;
      });
      return this.phase.quota * (100 - ratio) * 0.01;
    },
    surplusRatio() {
      let ratio = 0; // 总比例
      this.phase.twoList.forEach((item) => {
        ratio += item.allocateQuota;
      });
      return 100 - ratio;
    },
    // 技术经济的钱
    tecMoney() {
      // 如果用户输入了数值，那就是技术经济的钱，如果选择了比例，那就算出钱
      if (!this.phase.tec) {
        return 0;
      }
      if (this.phase.tec.allocateAmount) {
        return this.phase.tec.allocateAmount * 10000;
      } else if (this.phase.tec.allocateQuota) {
        return this.surplusMoney * this.phase.tec.allocateQuota * 0.01;
      } else {
        return 0;
      }
    },

    // 专业剩余占比
    majorMoney() {
      // 有技术经济
      if (this.tecMoney) {
        return this.surplusMoney - this.tecMoney;
      } else {
        return this.surplusMoney;
      }
    },

    // 增加其他的按钮是否隐藏
    addOtherButtonDisabled() {
      // 只要有一个，就禁用
      return this.phase.otherList.length > 0;
    },
  },

  watch: {
    phase: {
      handler() {
        console.log("phase changed ", this.index);
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations("allocate2024", ["setList"]), // 选择人员、修改比例，增加专业、增加其他都需要修改总的阶段列表

    // 删除技术经济
    removeTec() {
      this.list.splice(this.index, 1, {
        ...this.phase,
        tec: undefined,
      });
    },
    // 添加技术经济
    addTec() {
      this.list.splice(this.index, 1, {
        ...this.phase,
        tec: {
          type: "tech_dept",
          allocateQuota: 5,
          collapsed: false,
          staffList: [
            ...this.roleTypeList.map((type) => ({
              role: type.value,
              roleRatio:
                roleRatioList.find((item) => item.value === type.value).ratio *
                100,
              allocateUnit: "%",
              allocateRatio: 100,
            })),
          ],
        },
      });
    },

    // 改子项名称和比例
    changeGroupValue(payload) {
      const { index, item, key, value } = payload;
      this.phase.majorGroupList.splice(index, 1, {
        ...item,
        [key]: value,
      });
    },

    uploaded(files) {
      this.list[this.index].attachments = [
        ...this.phase.attachments,
        ...files.map((item) => {
          return {
            id: item.id,
            name: item.name,
            path: item.completePath,
          };
        }),
      ];
      this.setList([...this.list]);
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },

    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.phase.attachments.findIndex(
          (file) => file.id === item
        );
        if (index > -1) {
          this.phase.attachments.splice(index, 1);
        }
      });
      this.list[this.index] = this.phase;
      this.setList([...this.list]);
      this.selectedFileList = [];
    },

    // 增加专业
    plus() {
      this.visible = true;
    },
    onSelectChange(keys) {
      this.selectedRowKeys = keys;
    },
    cancel() {
      this.visible = false;
    },

    // 增加专业
    ok() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请选择专业类型！");
        return;
      }

      const params = [];
      this.selectedRowKeys.forEach((key) => {
        const obj = this.majorTypes.find((item) => item.key === key);
        params.push(obj);
      });

      if (params.length > 0) {
        const majorGroupList = this.phase.majorGroupList;

        this.phase.majorGroupList = [
          ...majorGroupList.map((item) => {
            return {
              ...item,
              list: [
                ...item.list,
                ...params
                  .filter((element) => {
                    return (
                      item.list.findIndex((obj) => obj.type === element.key) ===
                      -1
                    );
                  })
                  .map((element) => ({
                    type: element.key,
                    name: item.name,
                    allocateQuota: 10,
                    collapsed: false,

                    staffList: [
                      ...this.roleTypeList.map((type) => ({
                        role: type.value,

                        roleRatio:
                          roleRatioList.find(
                            (item) => item.value === type.value
                          ).ratio * 100,

                        allocateUnit: "%",
                        allocateRatio: 100,
                      })),
                    ],
                  })),
              ],
            };
          }),
        ];

        // 更新vuex，从而触发保存到 localStorage
        this.list[this.index] = this.phase;
        this.setList([...this.list]);
      }

      this.visible = false;
      this.selectedRowKeys = [];
    },

    deleteRatio(index) {
      this.$emit("deleteRatio", {
        phase: this.phase,
        ratioIndex: index,
      });
    },

    // copy() {
    //   // 复制上一个阶段到这个阶段
    //   const last = this.list[this.index - 1];
    //   this.list[this.index] = {
    //     ...this.phase,

    //     twoList: last.twoList.map((item) => ({
    //       ...item,
    //       id: undefined,
    //       staffList: item.staffList.map((staff) => ({ ...staff })),
    //     })),

    //     tec: {
    //       ...last.tec,
    //       id: undefined,
    //       staffList: last.tec.staffList.map((staff) => ({ ...staff })),
    //     },

    //     majorGroupList: last.majorGroupList.map((item) => {
    //       return {
    //         ...item,
    //         list: item.list.map((element) => {
    //           return {
    //             ...element,
    //             staffList: element.staffList.map((staff) => ({ ...staff })),
    //           };
    //         }),
    //       };
    //     }),

    //     // 其他类型
    //     otherList: last.otherList.map((item) => {
    //       return {
    //         ...item,
    //         staffList: Array.isArray(item.staffList)
    //           ? item.staffList.map((staff) => ({ ...staff }))
    //           : [],
    //       };
    //     }),
    //   };
    //   this.setList([...this.list]);
    // },

    // 增加子项
    addMajorGroup() {
      const groupList = this.phase.majorGroupList;
      const length = this.phase.majorGroupList.length;
      const lastGroup = groupList[length - 1]; // 上一个子项

      let newMajorGroup = {};

      if (lastGroup) {
        newMajorGroup = {
          id: null,
          majorGroup: `子项${groupList.length + 1}`,
          majorGroupQuota: lastGroup.majorGroupQuota,
          list: [
            ...lastGroup.list.map((item) => ({
              ...item,
              id: null,
              staffList: item.staffList.map((element) => {
                return {
                  ...element,
                  projectAllocateId: null,
                  id: null,
                };
              }),
            })),
          ],
        };
      } else {
        newMajorGroup = {
          majorGroup: `子项1`,
          majorGroupQuota: 100,
          list: [],
        };
      }

      this.phase.majorGroupList.push(newMajorGroup);

      // 更新vuex，从而触发保存到 localStorage
      this.list[this.index] = this.phase;
      this.setList([...this.list]);
    },

    // 增加其他
    addOther() {
      this.phase.otherList.push(
        ...this.otherTypeList.map((item) => {
          return {
            name: item.name,
            type: item.value,
            collapsed: false,
            allocateQuota: 5,
            staffList: [
              {
                allocateUnit: "%",
                allocateRatio: 100,
              },
            ],
          };
        })
      );

      // 更新vuex，从而触发保存到 localStorage
      this.list[this.index] = this.phase;
      this.setList([...this.list]);
    },
  },
};
</script>

<style lang="less" scoped>
.phase-title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
}

.list {
  background-color: #f5f5f5;
  padding: 16px;
}

.item {
  margin-bottom: 16px;
}
.item:last-child {
  margin-bottom: 0;
}

.header {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.ratio-title {
  display: flex;
  align-items: center;
}
.ratio-title > span {
  width: 120px;
  height: 32px;
  font-size: 13px;
  background: #1890ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.employee {
  margin-bottom: 12px;
}
.employee:last-child {
  margin-bottom: 0;
}

.other-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.other-list {
  padding: 12px;
}

.link-list {
  background-color: #f3f3f3;
  padding: 12px;
  margin-bottom: 16px;
  position: relative;
  min-height: 66px;

  .link {
    margin-bottom: 4px;
  }

  .control-bar {
    position: absolute;
    top: 0;
    right: 0;

    .control {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .control:hover {
      color: #1890ff;
    }
  }
}

.small-title {
  font-weight: bold;
  margin-bottom: 16px;
}

.cost-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 16px;
  margin-bottom: 8px;
}
.cost {
  background-color: #f3f3f3;
  padding: 12px;
}
</style>